<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!--  Content Start -->
    <div class="sabc_competition_contact_outer" v-else>
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                  <div class="sabc_competition_contact_inner">
                    <h1 v-if="this.$route.params.past">Competitions Archive</h1>
                    <h1 v-else>Competitions</h1>
                    <div class="sabc_competition_section category_list_area">
                        <div class="sabc_competition_tabs">
                            <ul>
                                <li style="display: flex;">
                                    <router-link :to="{ name: 'pastCompetitions'}" exact :exact-active-class="'active'">Current</router-link>
                                    <router-link :to="{ name: 'pastCompetitions', params:{past:'past'}}" exact :exact-active-class="'active'">Past Competitions</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="sabc_competition_contact_form_otr" v-if="liveCompetition">
                        <div class="sabc_competition_contact_form_inr">
                            <div class="mail-form" id="mail-form">
                                <img :src="$storageURL+competitionLive.image_path" alt="Competition Time" height="780px" width="780px" loading="lazy" /><br>
                                <router-link :to="{ name: 'worldcup', params: { slug: competitionLive.slug}}">
                                    <h2>{{ competitionLive.title}}</h2>
                                </router-link>
                                <p>{{competitionLive.introduction}}</p>
                                <span v-html="competitionLive.description"></span>
                                <router-link class="sabc_enter_btn" :to="{ name: 'worldcup', params: { slug: competitionLive.slug}}">
                                    <b>Enter Now</b>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="sabc_competition_list_sec">
                        <div class="sabc_competition_list_row" v-for="competition in competitions" :key="competition.id">
                            <div class="sabc_competition_list_pic">
                                <router-link :to="{ name: 'worldcup', params: { slug: competition.slug}}">
                                    <img :src="$storageURL+competition.image_path" height="180px" width="180px" loading="lazy"/>
                                </router-link>
                            </div>
                            <div class="sabc_competition_list_desc">
                                <h2>
                                    <router-link :to="{ name: 'worldcup', params: { slug: competition.slug}}">
                                        {{competition.title}}
                                    </router-link>
                                </h2>
                                <p>{{ competition.introduction }}</p>
                                <p v-if="competition.end_date">
                                    <b>Competition closed {{competition.end_date | formatDate('DD MMMM')}}</b>
                                </p>
                                <button v-else>
                                    <router-link class="sabc_current_btn" :to="{ name: 'worldcup', params: { slug: competition.slug}}"><b>Enter Now</b></router-link>
                                </button>
                                <!-- <router-link class="sabc_current_btn" :to="{ name: 'worldcup', params: { slug: competition.slug}}">
                                    Enter Now
                                </router-link> -->
                            </div>
                        </div>
                        <div v-if="competitions.length == 0 && competitionLive.length == 0">
                            <b>There are currently no active competitions running. Please come back soon.</b>
                        </div>
                    </div>
                  </div>
                </div>
                <SideBar :editorsPick="editorsPick" :bannerImages="bannerImages" :bannerEmbed="bannerEmbed"></SideBar>
            </div>
        </div>
    </div>
    <!--  Content end -->
</template>

<script>
    import SideBar from '@/components/common/sidebar/sidebar';
    export default {
        name: 'worldcup',
        data() {
            return {
                end_Date : '',
                currentDate : new Date(),
                competitions :[],
                competitionLive :[],
                editorsPick: [],
                bannerImages : [],
                bannerEmbed : [],
                is_data_ready: false,
                liveCompetition: false,
                count: 1,
                show: true,   
            }
        },
        mounted() {
            this.getContactPageData(this.count);
       },
        serverPrefetch() {
            this.getContactPageData(this.count);
        },
        computed: {
        },
        components: {SideBar},
        metaInfo() {
            if (this.is_data_ready) {
                
                let title = 'Competitions List | SABC Sport';
                let description = 'Competitions List | SABC Sport';
                if(this.$route.params.past){
                    title = 'Past Competitions List | SABC Sport'
                    description = 'Past Competitions List | SABC Sport';
                }
                let keywords = 'Competitions List,competition at SABC Sport';

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },
        watch: {
            $route(to, from) {
                this.getContactPageData();
            }
        },
        methods: {
            getContactPageData() {
                this.competitions = [];
                this.competitionLive = [];
                this.is_data_ready = false;
                let params = [];
                if(this.$route.params.past){
                    params = {flag: this.$route.params.past};
                }
                return this.$axios.get(this.$GetCompetitionsListData,{params})
                    .then(response => {
                        if (response.data) {
                            this.is_data_ready = true;
                            this.competitions = response.data.result.competition;
                            this.competitionLive = response.data.result.competitionLive;
                            this.liveCompetition = response.data.result.liveCompetition;
                            this.editorsPick = response.data.result.editorsPick;
                            let end_Date = new Date(response.data.result.competitionLive.end_date);
                            let currentDate = new Date();
                            if (end_Date > currentDate) {
                                this.show = true;
                            }
                            else{
                                this.show =false;
                            }
                        }
                    })
                    .catch(error => {
                    })
            },
            
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/competition_form.scss';
@import '~@/assets/scss/right_sidebar.scss';
@import '~@/assets/scss/featured.scss';
@import '~@/assets/scss/competition_list.scss';
@import '~@/assets/scss/competition_tabs.scss';
</style>