<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!--  Content Start -->
    <div class="sabc_competition_contact_outer" v-else>
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                    <div class="send_mail" v-if="sendMessgar">
                        {{competitions.success_message}}
                        <i @click="closeMessage()">x</i>
                    </div>
                  <div class="sabc_competition_contact_inner">
                    <div class="sabc_competition_section category_list_area">
                        <div class="sabc_competition_tabs">
                            <ul>
                                <li style="display: flex;">
                                    <img class="play_icon" src="/assets/img/icon-back-arrow.svg" alt="Back Icon"/>
                                   <router-link :to="{ name: 'pastCompetitions'}" style="padding-bottom:1px">All Competitions</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <h1>{{ competitions.title}}</h1>
                    <div class="sabc_competition_contact_form_otr">
                      <div class="sabc_competition_contact_form_inr">
                        <div class="mail-form" id="mail-form">
                            <img :src="$storageURL+competitions.image_path" alt="Competition Time" height="780px" width="780px" loading="lazy" /><br>
                            <h2>{{competitions.introduction}}</h2>
                            <span v-html="competitions.description"></span>
                            <div v-if="competitions.winner_list.length">
                                <b>Congratulations to the winners:</b>
                                <div v-for="winner in competitions.winner_list" :key="winner.id">{{winner}}</div>
                            </div>
                            <div v-if="competitions.competitionLive">
                                <div v-if="competitions.form_link">
                                    <button><a class="sabc_enter_btn" :href="competitions.form_link" target="_blank"><b>Enter Now</b></a> </button>
                                </div>
                                <form v-else v-on:submit.prevent="submitForm">
                                    <label for="select"><b>{{competitions.question}}</b></label>
                                    <p>
                                        <label v-if="competitions.ans1" class="sabc_cmp_select_option" for="option1"><input type="radio" required class="" id="option1" name="select" :value="competitions.ans1" v-model="form.select"> {{competitions.ans1}}</label>
                                        <label v-if="competitions.ans2" class="sabc_cmp_select_option" for="option2"><input type="radio" required class="" id="option2" name="select" :value="competitions.ans2" v-model="form.select"> {{competitions.ans2}} </label>
                                        <label v-if="competitions.ans3" class="sabc_cmp_select_option" for="option3"><input type="radio" required class="" id="option3" name="select" :value="competitions.ans3" v-model="form.select"> {{competitions.ans3}}</label>
                                    </p>
                                    <label for="name">Name and Surname:</label>
                                    <input class="sabc_competition_contact_form_control" required type="text" id="name" name="name" v-model="form.name"/>
                                    <input type="hidden" id="mailType" name="mailType" v-model="form.mailType"/>
                                    <label for="email">Email:</label>
                                    <input class="sabc_competition_contact_form_control" required type="email" id="email" name="email" v-model="form.email"/>
                                    <label for="phone">Contact Number:</label>
                                    <input class="sabc_competition_contact_form_control" required type="text"  id="phone" name="phone" v-model="form.phone"/>
                                
                                    <p><input class="sabc_cmp_term_option" required type="checkbox">I have read the
                                        <router-link style="color:#0C2251" :to="{ name: 'competitiontc', params:{competitionid: competitions.id}}" target="_blank"><b>Terms & Conditions</b></router-link></p>
                                    <label v-if="captchaErrorText" for="captcha" class="captchaLable">The reCAPTCHA was invalid. Go back and try it again.</label>
                                    <vue-recaptcha @verify="onVerify" @expired="onExpired" sitekey="6LdQe68UAAAAAOqGZCRcfH3YyboxvQZzlm3TdCFJ"></vue-recaptcha>
                                    <button type="submit" :disabled="form.name == '' || form.email == '' || form.phone == ''" class="sabc_send_btn">SEND</button>
                                </form>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SideBar :editorsPick="editorsPick" :bannerImages="bannerImages" :bannerEmbed="bannerEmbed"></SideBar>
            </div>
        </div>
    </div>
    <!--  Content end -->
</template>

<script>
    import SideBar from '@/components/common/sidebar/sidebar';
    import { VueRecaptcha } from 'vue-recaptcha';

    export default {
        name: 'worldcup',
        data() {
            return {
                competitions :[],
                editorsPick: [],
                bannerImages : [],
                bannerEmbed : [],
                sendMessgar: false,
                captchaVerify: false,
                captchaVerifyResponse: '',
                captchaErrorText: false,
                is_data_ready: false,
                display_from: false,
                hideButton : true,
                form : {
                    name: '',
                    email: '',
                    phone: '',
                    select: '',
                    mailType: 'win-ticker',
                },
                
            }
        },
        components: {SideBar, VueRecaptcha },
        mounted() {
            this.getContactPageData();
       },
        serverPrefetch() {
            this.getContactPageData();
        },
        computed: {
        },
        metaInfo() {
           if (this.is_data_ready) {
                
                let title = this.competitions.meta_title;
                let description = this.competitions.meta_description;
                let keywords = this.competitions.meta_keywords;

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },

        methods: {
            getContactPageData() {
                this.is_data_ready = false;
                let params = {slug: this.$route.params.slug};
                return this.$axios.get(this.$GetCompetitionsList,{params})
                    .then(response => {
                        if (response.data) {
                            this.is_data_ready = true;
                            this.competitions = response.data.result.competitions;
                            this.editorsPick = response.data.result.editorsPick;
                        }
                    })
                    .catch(error => {
                    })
            },
            submitForm(){
                this.url = this.$PostContactPage;
                this.sendMessgar = false;
                this.captchaErrorText = false;
                if(this.captchaVerify){
                    this.$axios.post(this.url, this.form)
                        .then((res) => {
                            this.sendMessgar = true;
                            this.form = {name: '',email: '',phone: ''};
                            window.scrollTo({top: 0,left: 0,behavior: 'smooth'});
                            this.resetRecaptcha();
                            // $('.mail-form').hide();
                            // document.getElementById("mail-form").style.display="none";
                        })
                        .catch((error) => {
                            console.log(error)
                        }).finally(() => {
                            //Perform action in always
                        });
                }else{
                    this.captchaErrorText = true;
                }
            },
            closeMessage(){
                this.sendMessgar = false;
                window.location.href = '/';
            },
            onVerify: function (response) {
                this.captchaErrorText = false;
                this.captchaVerify = true;
                this.captchaVerifyResponse = response;
            },
            onExpired: function () {
                this.captchaVerify = false;
                this.captchaVerifyResponse = '';
            },
            resetRecaptcha() {
                this.$refs.recaptcha.reset() // Direct call reset method
            }
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/competition_form.scss';
@import '~@/assets/scss/right_sidebar.scss';
@import '~@/assets/scss/featured.scss';
@import '~@/assets/scss/competition_list.scss';
@import '~@/assets/scss/competition_tabs.scss';
</style>