<template>
    <div class="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <SideBarAdd v-if="bannerImages != null" :bannerImages="bannerImages"></SideBarAdd>
        <SideBarEmbed v-if="bannerEmbed != null" :bannerEmbed="bannerEmbed"></SideBarEmbed>
        <SideBarMatches></SideBarMatches>
        <SideBarNews :editorsPick="editorsPick" :olympicsTable="olympicsTable"></SideBarNews>
    </div>
</template>
<script>
    import SideBarAdd from '@/components/common/sidebar/sidebar-add';
    import SideBarEmbed from '@/components/common/sidebar/sidebar-embed';
    import SideBarNews from '@/components/common/sidebar/sidebar-news';
    import SideBarMatches from '@/components/common/sidebar/sidebar-matches';

    export default {
        name: 'SideBar',
        props: ['editorsPick','bannerImages','bannerEmbed', 'olympicsTable'],
        data() {
            return {
            }
        },

        components: {
            SideBarAdd, SideBarNews, SideBarMatches, SideBarEmbed
        },

        mounted() {
        },

        methods: {}
    }
</script>
