<template>
    <!--  article siderbar ad start -->
    <div class="sabc_article_sidebar_embed" v-if="bannerEmbed.bannerItems && bannerEmbed.bannerItems.length">
        <div class="add_embed_code">
            <!-- The slideshow -->
            <div v-for="embed in bannerEmbed.bannerItems">
                <div v-html="embed.content"></div>
            </div>
        </div>
    </div>
    <!--  article siderbar ad end -->
</template>
<script>
    export default {
        name: 'SideBarEmbed',
        props: ['bannerEmbed'],
        data() {
            return {
            }
        },
        mounted() {
        },
        methods: {}
    }
</script>
