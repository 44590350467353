<template>
    <!--  article siderbar ad start -->
    <div class="sabc_article_sidebar_ad" v-if="bannerImages.bannerItems && bannerImages.bannerItems.length">
        <div class="swiper mySwiper3">
            <!-- The slideshow -->
            <div class="swiper-wrapper">
                <div class="swiper-slide active" v-for="images in bannerImages.bannerItems">
                    <img v-if="images.image_link == 'null'" :src="$resizeImage(images.content, 359, 299)" width="359" height="299" alt="image" loading="lazy" >
                    <a v-else :href="images.image_link" aria-label="channel slider">
                        <img :src="$resizeImage(images.content, 359, 299)" width="359" height="299" alt="image" loading="lazy" >
                    </a>
                </div>
            </div>
            <!-- Left and right controls -->
            <div class="swiper-button-prev" v-if="bannerImages.bannerItems && bannerImages.bannerItems.length > 1"></div>
            <div class="swiper-button-next" v-if="bannerImages.bannerItems && bannerImages.bannerItems.length > 1"></div>
            <div class="swiper-pagination" v-if="bannerImages.bannerItems && bannerImages.bannerItems.length > 1"></div>
        </div>
    </div>
    <!--  article siderbar ad end -->
</template>
<script>
    import { Swiper, Navigation } from "swiper";
    Swiper.use([ Navigation ]);
    export default {
        name: 'SideBarAdd',
        props: ['bannerImages'],
        data() {
            return {
            }
        },
        mounted() {
            new Swiper('.mySwiper3', {
                slidesPerView: 1,
                observer: true,
                observeParents: true,
                paginationClickable: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });
        },
        methods: {}
    }
</script>
