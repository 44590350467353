<template>
    <!--  article siderbar premeir league start -->
        <div class="sabc_article_sidebar_prleague" v-if="tournaments.length">
            <div v-for="tournament in tournaments" :key="tournament.id">
                <div v-if="tournament.footballMatches && tournament.footballMatches.length != 0">
                    <h2>
                        {{ tournament.name}}
                    </h2>
                    <div class="sabc_article_sidebar_prleague_row" v-for="(matches,date_key) in tournament.footballMatches" :key="date_key">
                        <h3>
                            {{ date_key }}
                            <i class="sabc_article_down sabc_article_arrow arrow_pointer" :class="{ 'active' : tournament.isVisible }" @click="toggleShowList(tournament)" ></i>
                        </h3>
                        <ul v-if="!tournament.isVisible" style="display: block;">
                            <li v-for="match in matches" :key="match.id" :class="{ 'sabc_live_team' : match.status == 'inprogress'}">
                                <router-link :to="{ name:'match_detail.overview', params: { slug: $slugify(tournament.name + ' ' +  $getSafe(()=>$filterTeam(match.competitors, true).team.name) + ' vs ' + $getSafe(()=>$filterTeam(match.competitors, false).team.name)), date: (match.datetime).split(' ')[0], matchId: match.id } }" style="display:flex;">
                                    <span v-if="(match.status == 'Finished')">FT</span>
                                    <span v-else-if="(match.status == 'inprogress')">LIVE</span>
                                    <span v-else>{{match.status}}</span>
                                    <div class="sabc_article_sidebar_prleague_team">
                                        <p>
                                            {{ $getSafe(() => $filterTeam(match.competitors, true).team.name) }}
                                            <label>
                                                {{ (match.ft)? match.ft.split('-')[0] :match.ht.split('-')[1] }}
                                            </label>
                                        </p>
                                        <p>
                                            {{ $getSafe(() => $filterTeam(match.competitors, false).team.name) }}
                                            <label>
                                                {{ (match.ft)? match.ft.split('-')[1]: match.ht.split('-')[1] }}
                                            </label>
                                        </p>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="sabc_article_sidebar_prleague_row">
                        <h3>
                            Saturday 26th October
                            <i class="sabc_article_down sabc_article_arrow"></i>
                        </h3>
                        <ul>
                            <li>
                                <span>
                                  FT
                                </span>
                                <div class="sabc_article_sidebar_prleague_team">
                                    <p>
                                        Watford
                                        <label>
                                            2
                                        </label>
                                    </p>
                                    <p>
                                        Liverpool
                                        <label>
                                            1
                                        </label>
                                    </p>
                                </div>
                            </li>
                            <li class="sabc_live_team">
                                <span>
                                  FT
                                </span>
                                <div class="sabc_article_sidebar_prleague_team">
                                    <p>
                                        Watford
                                        <label>
                                            2
                                        </label>
                                    </p>
                                    <p>
                                        Liverpool
                                        <label>
                                            1
                                        </label>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    <!--  article siderbar premeir league end -->
</template>
<script>
    export default {
        name: 'SideBarMatches',
        data() {
            return {
                tournaments: []
            }
        },
        mounted() {
            this.getTournamentMatches();
        },
        serverPrefetch() {
            return this.getTournamentMatches();
        },
        methods: {
            getTournamentMatches() {
                return [];
                // return this.$axios.get(this.$FB_WIDGET_MATCHES_API)
                //     .then(response => {
                //         this.tournaments = response.data.result;
                //     })
                //     .catch(error => console.log(error));
            },
            toggleShowList(data) {
                this.tournaments = this.tournaments.map((torunament, index) => {
                    if (index === data) {
                        return {
                            data: torunament.data,
                            isVisible: !torunament.isVisible,
                        };
                    }
                    return torunament;
                });
                data.isVisible = !data.isVisible;
            },
        }
    }
</script>
<style>
    .arrow_pointer {
        cursor: pointer;
    }
</style>
